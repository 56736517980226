export const GET_TEST_QUESTIONS_REQUEST = "GET_TEST_QUESTIONS_REQUEST";
export const GET_TEST_QUESTIONS_SUCCESS = "GET_TEST_QUESTIONS_SUCCESS";
export const GET_TEST_QUESTIONS_FAILURE = "GET_TEST_QUESTIONS_FAILURE";
export const RESET_TEST_QUESTION_DATA = "RESET_TEST_QUESTION_DATA";

export const GET_ALL_SUBJECT_LESSION_QUESTION_REQUEST =
  "GET_ALL_SUBJECT_LESSION_QUESTION_REQUEST";
export const GET_ALL_SUBJECT_LESSION_QUESTION_SUCCESS =
  "GET_ALL_SUBJECT_LESSION_QUESTION_SUCCESS";
export const GET_ALL_SUBJECT_LESSION_QUESTION_FAILURE =
  "GET_ALL_SUBJECT_LESSION_QUESTION_FAILURE";
export const RESET_SUBJECT_LESSION_QUESTION_DATA = "RESET_TEST_QUESTION_DATA";
