import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-circular-progressbar/dist/styles.css";
import "./index.css";
import "./media.css";
import i18n from "i18next";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { initReactI18next } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import { store } from "./store/reducer";
import { Provider } from "react-redux";

const timeStamp = Date.now();

const en_res = await fetch(
  `${process.env.REACT_APP_PUBLIC_LANGUAGE_URL}/en.json?${timeStamp}`,
  {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }
)
  .then(function (response) {
    return response.json();
  })
  .then(function (myJson) {
    return myJson;
  });

const cz_res = await fetch(
  `${process.env.REACT_APP_PUBLIC_LANGUAGE_URL}/cz.json?${timeStamp}`,
  {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }
)
  .then(function (response) {
    return response.json();
  })
  .then(function (myJson) {
    return myJson;
  });

const jap_res = await fetch(
  `${process.env.REACT_APP_PUBLIC_LANGUAGE_URL}/jap.json?${timeStamp}`,
  {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }
)
  .then(function (response) {
    return response.json();
  })
  .then(function (myJson) {
    return myJson;
  });

i18n.use(initReactI18next).init({
  resources: {
    EN: {
      translation: en_res,
    },
    jap: {
      translation: jap_res,
    },
    CZ: {
      translation: cz_res,
    },
  },
  lng: sessionStorage.getItem("langType") || "CZ",
  fallbackLng: sessionStorage.getItem("langType") || "CZ",
  interpolation: {
    escapeValue: false,
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
