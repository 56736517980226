import {
  GET_BEST_AND_WORST_STAT_FAILURE,
  GET_BEST_AND_WORST_STAT_REQUEST,
  GET_BEST_AND_WORST_STAT_SUCCESS,
  GET_DISTRICT_SCHOOL_AVG_STAT_FAILURE,
  GET_DISTRICT_SCHOOL_AVG_STAT_REQUEST,
  GET_DISTRICT_SCHOOL_AVG_STAT_SUCCESS,
  GET_EASIEST_AND_HARDEST_TOPIC_STAT_FAILURE,
  GET_EASIEST_AND_HARDEST_TOPIC_STAT_REQUEST,
  GET_EASIEST_AND_HARDEST_TOPIC_STAT_SUCCESS,
  GET_SCHOOL_AND_PERCENTAGE_STAT_FAILURE,
  GET_SCHOOL_AND_PERCENTAGE_STAT_REQUEST,
  GET_SCHOOL_AND_PERCENTAGE_STAT_SUCCESS,
  RESET_SCHOOL_AND_PERCENTAGE_STAT_DATA,
  NUMBER_OF_SCHOOL_STAT_REQUEST,
  NUMBER_OF_SCHOOL_STAT_SUCCESS,
  NUMBER_OF_SCHOOL_STAT_FAILURE,
} from "../constants/schoolStatistics";

// const initialState = {};

export function SchoolStatistics(state = {}, action) {
  switch (action.type) {
    case GET_BEST_AND_WORST_STAT_REQUEST:
    case GET_SCHOOL_AND_PERCENTAGE_STAT_REQUEST:
    case GET_EASIEST_AND_HARDEST_TOPIC_STAT_REQUEST:
    case GET_DISTRICT_SCHOOL_AVG_STAT_REQUEST:
    case NUMBER_OF_SCHOOL_STAT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BEST_AND_WORST_STAT_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolStatData: action.payload,
      };
    case GET_EASIEST_AND_HARDEST_TOPIC_STAT_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolEasiestAndHardData: action.payload,
      };
    case GET_SCHOOL_AND_PERCENTAGE_STAT_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolPercentageData: action.payload,
      };
    case GET_DISTRICT_SCHOOL_AVG_STAT_SUCCESS:
      return {
        ...state,
        loading: false,
        getDistrictSchoolAvgData: action.payload,
      };
    case RESET_SCHOOL_AND_PERCENTAGE_STAT_DATA:
      return {
        ...state,
        loading: false,
        schoolPercentageData: {},
      };
    case NUMBER_OF_SCHOOL_STAT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalNumberOfSchoolData: action.payload,
      };
    case GET_BEST_AND_WORST_STAT_FAILURE:
    case GET_SCHOOL_AND_PERCENTAGE_STAT_FAILURE:
    case GET_EASIEST_AND_HARDEST_TOPIC_STAT_FAILURE:
    case GET_DISTRICT_SCHOOL_AVG_STAT_FAILURE:
    case NUMBER_OF_SCHOOL_STAT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
