export const USER_SING_UP_REQUEST = "USER_SING_UP_REQUEST";
export const USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS";
export const USER_SIGN_UP_FAILURE = "USER_SIGN_UP_FAILURE";
export const USER_SIGN_UP_DATA_RESET = "USER_SIGN_UP_DATA_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_DATA_RESET = "USER_LOGIN_DATA_RESET";

export const GET_USER_BY_COOKIE_REQUEST = "GET_USER_BY_COOKIE_REQUEST";
export const GET_USER_BY_COOKIE_SUCCESS = "GET_USER_BY_COOKIE_SUCCESS";
export const GET_USER_BY_COOKIE_FAILURE = "GET_USER_BY_COOKIE_FAILURE";

export const GET_USER_BY_TOKEN_REQUEST = "GET_USER_BY_TOKEN_REQUEST";
export const GET_USER_BY_TOKEN_SUCCESS = "GET_USER_BY_TOKEN_SUCCESS";
export const GET_USER_BY_TOKEN_FAILURE = "GET_USER_BY_TOKEN_FAILURE";