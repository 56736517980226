export const GET_PARENT_SCHOOL_DETAILS_REQUEST =
  "GET_PARENT_SCHOOL_DETAILS_REQUEST";
export const GET_PARENT_SCHOOL_DETAILS_SUCCESS =
  "GET_PARENT_SCHOOL_DETAILS_SUCCESS";
export const GET_PARENT_SCHOOL_DETAILS_FAILURE =
  "GET_PARENT_SCHOOL_DETAILS_FAILURE";

export const GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_REQEUST =
  "GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_REQEUST";
export const GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_SUCCESS =
  "GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_SUCCESS";
export const GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_FAILURE =
  "GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_FAILURE";

export const STORE_USER_SCHOOL_DATA = "STORE_USER_SCHOOL_DATA";
export const STORE_SCHOOL_GRADE_DATA = "STORE_SCHOOL_GRADE_DATA";
export const STORE_SCHOOL_TYPE_DATA = "STORE_SCHOOL_TYPE_DATA";

export const STORE_PARENT_CHILD_DATA = "STORE_PARENT_CHILD_DATA";

export const GET_PARENT_STUDENT_CHILD_LIST_REQUEST =
  "GET_PARENT_STUDENT_CHILD_LIST_REQUEST";
export const GET_PARENT_STUDENT_CHILD_LIST_SUCCESS =
  "GET_PARENT_STUDENT_CHILD_LIST_SUCCESS";
export const GET_PARENT_STUDENT_CHILD_LIST_FAILURE =
  "GET_PARENT_STUDENT_CHILD_LIST_FAILURE";

export const GET_DIFFERENCE_ELEMENTARY_SCHOOL_REQUEST =
  "GET_DIFFERENCE_ELEMENTARY_SCHOOL_REQUEST";
export const GET_DIFFERENCE_ELEMENTARY_SCHOOL_SUCCESS =
  "GET_DIFFERENCE_ELEMENTARY_SCHOOL_SUCCESS";
export const GET_DIFFERENCE_ELEMENTARY_SCHOOL_FAILURE =
  "GET_DIFFERENCE_ELEMENTARY_SCHOOL_FAILURE";

export const GET_DIFFERENCE_HIGH_SCHOOL_REQUEST =
  "GET_DIFFERENCE_HIGH_SCHOOL_REQUEST";
export const GET_DIFFERENCE_HIGH_SCHOOL_SUCCESS =
  "GET_DIFFERENCE_HIGH_SCHOOL_SUCCESS";
export const GET_DIFFERENCE_HIGH_SCHOOL_FAILURE =
  "GET_DIFFERENCE_HIGH_SCHOOL_FAILURE";
