export const CREATE_CREDIT_REQUEST = "CREATE_CREDIT_REQUEST";
export const CREATE_CREDIT_SUCCESS = "CREATE_CREDIT_SUCCESS";
export const CREATE_CREDIT_FAILURE = "CREATE_CREDIT_FAILURE";

export const GET_USER_SCHOOL_LIST_REQUEST = "GET_USER_SCHOOL_LIST_REQUEST";
export const GET_USER_SCHOOL_LIST_SUCCESS = "GET_USER_SCHOOL_LIST_SUCCESS";
export const GET_USER_SCHOOL_LIST_FAILURE = "GET_USER_SCHOOL_LIST_FAILURE";

export const GET_REMAINING_CREDIT_COUNT_REQUEST =
  "GET_REMAINING_CREDIT_COUNT_REQUEST";
export const GET_REMAINING_CREDIT_COUNT_SUCCESS =
  "GET_REMAINING_CREDIT_COUNT_SUCCESS";
export const GET_REMAINING_CREDIT_COUNT_FAILURE =
  "GET_REMAINING_CREDIT_COUNT_FAILURE";

export const GET_PARENT_CHILD_LIST_REQUEST = "GET_PARENT_CHILD_LIST_REQUEST";
export const GET_PARENT_CHILD_LIST_SUCCESS = "GET_PARENT_CHILD_LIST_SUCCESS";
export const GET_PARENT_CHILD_LIST_FAILURE = "GET_PARENT_CHILD_LIST_FAILURE";

export const TOTAL_EDU_PRGS = "TOTAL_EDU_PRGS";
export const TOTAL_SCHOOLS = "TOTAL_SCHOOLS";