// const initialState = {};

import {
  GET_ALL_SUBJECT_LESSION_QUESTION_FAILURE,
  GET_ALL_SUBJECT_LESSION_QUESTION_REQUEST,
  GET_ALL_SUBJECT_LESSION_QUESTION_SUCCESS,
  GET_TEST_QUESTIONS_FAILURE,
  GET_TEST_QUESTIONS_REQUEST,
  GET_TEST_QUESTIONS_SUCCESS,
  RESET_TEST_QUESTION_DATA,
} from "../constants/test";

export function Test(state = {}, action) {
  switch (action.type) {
    case GET_TEST_QUESTIONS_REQUEST:
    case GET_ALL_SUBJECT_LESSION_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEST_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        testQuestionData: action.payload,
      };
    case GET_ALL_SUBJECT_LESSION_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subjectLessionQuestionData: action.payload,
      };
    case GET_TEST_QUESTIONS_FAILURE:
    case GET_ALL_SUBJECT_LESSION_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RESET_TEST_QUESTION_DATA:
      return {
        ...state,
        loading: false,
        testQuestionData: undefined,
      };
    default:
      return state;
  }
}
