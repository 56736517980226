export const GET_BEST_AND_WORST_STAT_REQUEST =
  "GET_BEST_AND_WORST_STAT_REQUEST";
export const GET_BEST_AND_WORST_STAT_SUCCESS =
  "GET_BEST_AND_WORST_STAT_SUCCESS";
export const GET_BEST_AND_WORST_STAT_FAILURE =
  "GET_BEST_AND_WORST_STAT_FAILURE";

export const GET_EASIEST_AND_HARDEST_TOPIC_STAT_REQUEST =
  "GET_EASIEST_AND_HARDEST_TOPIC_STAT_REQUEST";
export const GET_EASIEST_AND_HARDEST_TOPIC_STAT_SUCCESS =
  "GET_EASIEST_AND_HARDEST_TOPIC_STAT_SUCCESS";
export const GET_EASIEST_AND_HARDEST_TOPIC_STAT_FAILURE =
  "GET_EASIEST_AND_HARDEST_TOPIC_STAT_FAILURE";

export const GET_DISTRICT_SCHOOL_AVG_STAT_REQUEST =
  "GET_DISTRICT_SCHOOL_AVG_STAT_REQUEST";
export const GET_DISTRICT_SCHOOL_AVG_STAT_SUCCESS =
  "GET_DISTRICT_SCHOOL_AVG_STAT_SUCCESS";
export const GET_DISTRICT_SCHOOL_AVG_STAT_FAILURE =
  "GET_DISTRICT_SCHOOL_AVG_STAT_FAILURE";

export const GET_SCHOOL_AND_PERCENTAGE_STAT_REQUEST =
  "GET_SCHOOL_AND_PERCENTAGE_STAT_REQUEST";
export const GET_SCHOOL_AND_PERCENTAGE_STAT_SUCCESS =
  "GET_SCHOOL_AND_PERCENTAGE_STAT_SUCCESS";
export const GET_SCHOOL_AND_PERCENTAGE_STAT_FAILURE =
  "GET_SCHOOL_AND_PERCENTAGE_STAT_FAILURE";
export const RESET_SCHOOL_AND_PERCENTAGE_STAT_DATA =
  "RESET_SCHOOL_AND_PERCENTAGE_STAT_DATA";

export const NUMBER_OF_SCHOOL_STAT_REQUEST = "NUMBER_OF_SCHOOL_STAT_REQUEST";
export const NUMBER_OF_SCHOOL_STAT_SUCCESS = "NUMBER_OF_SCHOOL_STAT_SUCCESS";
export const NUMBER_OF_SCHOOL_STAT_FAILURE = "NUMBER_OF_SCHOOL_STAT_FAILURE";
