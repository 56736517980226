import { createStore, applyMiddleware, combineReducers } from "redux";
// import { logger } from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { LanguageType } from "./LanguageType";
import { User } from "./User";
import { Region } from "./Region";
import { SchoolStatistics } from "./SchoolStatistics";
import { Test } from "./Test";
import { OtherCss } from "./OtherCss";
import { ParentSchoolDetails } from "./ParentSchoolDetails";
import { StudentTopicLessonReducer } from "./StudentTopicLessonReducer";
import { Content } from "./Content";
import { shoppingCartReducer } from "./shoppingCartReducer";
import { SocialIcon } from "./SocialIcon";
import { FreeTrial } from "./FreeTrial";

const appReducer = combineReducers({
  languageType: LanguageType,
  user: User,
  region: Region,
  schoolStatistic: SchoolStatistics,
  test: Test,
  othercss: OtherCss,
  parentSchoolDetails: ParentSchoolDetails,
  studentTopicLesson: StudentTopicLessonReducer,
  content: Content,
  shoppingCart: shoppingCartReducer,
  socialIcon: SocialIcon,
  freeTrail: FreeTrial,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const middlewareList = [thunk];
const middleware = composeWithDevTools(applyMiddleware(...middlewareList));

export const store = createStore(rootReducer, middleware);
