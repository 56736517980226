// import axios from "axios";
import { SET_SCROLLED_FOOTER } from "../constants/otherCSS";

export function OtherCss(state = {}, action) {
    switch (action.type) {
     
      case SET_SCROLLED_FOOTER:
        return {
          ...state,
          loading: false,
          setScrolledFooterData: action.payload,
        };
      default:
        return state;
    }
  }