import {
  GET_APPROACH_TEXT_DATA_FAILURE,
  GET_APPROACH_TEXT_DATA_REQUEST,
  GET_APPROACH_TEXT_DATA_SUCCESS,
} from "../constants/content";

export function Content(state = {}, action) {
  switch (action.type) {
    case GET_APPROACH_TEXT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_APPROACH_TEXT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        contentApproachData: action.payload,
      };
    case GET_APPROACH_TEXT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
