import {
  GET_ALL_CITY_BY_ID_FAILURE,
  GET_ALL_CITY_BY_ID_REQUEST,
  GET_ALL_CITY_BY_ID_SUCCESS,
  GET_ALL_DISTRICT_BY_ID_LIST_FAILURE,
  GET_ALL_DISTRICT_BY_ID_LIST_REQUEST,
  GET_ALL_DISTRICT_BY_ID_LIST_SUCCESS,
  GET_ALL_DISTRICT_LIST_FAILURE,
  GET_ALL_DISTRICT_LIST_REQUEST,
  GET_ALL_DISTRICT_LIST_SUCCESS,
  GET_ALL_REGION_LIST_FAILURE,
  GET_ALL_REGION_LIST_REQUEST,
  GET_ALL_REGION_LIST_SUCCESS,
  GET_HIGH_SCHOOL_LIST_FAILURE,
  GET_HIGH_SCHOOL_LIST_REQUEST,
  GET_HIGH_SCHOOL_LIST_SUCCESS,
  GET_SCHOOLS_BY_CITY_ID_FAILURE,
  GET_SCHOOLS_BY_CITY_ID_REQUEST,
  GET_SCHOOLS_BY_CITY_ID_SUCCESS,
  STORE_DISTRICT_NAME_DATA,
  STORE_SCHOOL_DATA,
} from "../constants/region";

export function Region(state = {}, action) {
  switch (action.type) {
    case GET_ALL_REGION_LIST_REQUEST:
    case GET_ALL_DISTRICT_LIST_REQUEST:
    case GET_ALL_DISTRICT_BY_ID_LIST_REQUEST:
    case GET_ALL_CITY_BY_ID_REQUEST:
    case GET_SCHOOLS_BY_CITY_ID_REQUEST:
    case GET_HIGH_SCHOOL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DISTRICT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        districtList: action.payload,
      };
    case GET_ALL_CITY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        citListData: action.payload,
      };
    case GET_ALL_DISTRICT_BY_ID_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        districtByIdList: action.payload,
      };
    case GET_ALL_REGION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        regionList: action.payload,
      };
    case GET_SCHOOLS_BY_CITY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolListByCityIdData: action.payload,
      };
      case GET_HIGH_SCHOOL_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          getHighSchoolData: action.payload,
        };
    case STORE_SCHOOL_DATA:
      return {
        ...state,
        loading: false,
        schoolData: action.payload,
      };
    case STORE_DISTRICT_NAME_DATA:
      return {
        ...state,
        loading: false,
        getDistrictNameData: action.payload,
      };
    case GET_ALL_REGION_LIST_FAILURE:
    case GET_ALL_DISTRICT_LIST_FAILURE:
    case GET_ALL_DISTRICT_BY_ID_LIST_FAILURE:
    case GET_ALL_CITY_BY_ID_FAILURE:
    case GET_SCHOOLS_BY_CITY_ID_FAILURE:
    case GET_HIGH_SCHOOL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
