import {
  GET_LANGUAGE_LIST_FAILURE,
  GET_LANGUAGE_LIST_REQEUST,
  GET_LANGUAGE_LIST_SUCCESS,
} from "../constants/languageType";

// const initialState = {};

export function LanguageType(state = {}, action) {
  switch (action.type) {
    case GET_LANGUAGE_LIST_REQEUST:
      return {
        ...state,
        loading: true,
      };
    case GET_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        languageTypeData: action.payload,
      };
    case GET_LANGUAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
