import {
  USER_LOGIN_DATA_RESET,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_SIGN_UP_DATA_RESET,
  USER_SIGN_UP_FAILURE,
  USER_SIGN_UP_SUCCESS,
  USER_SING_UP_REQUEST,
  GET_USER_BY_COOKIE_REQUEST,
  GET_USER_BY_COOKIE_SUCCESS,
  GET_USER_BY_COOKIE_FAILURE,
  GET_USER_BY_TOKEN_REQUEST,
  GET_USER_BY_TOKEN_SUCCESS,
  GET_USER_BY_TOKEN_FAILURE,
} from "../constants/user";

export function User(state = {}, action) {
  switch (action.type) {
    case USER_SING_UP_REQUEST:
    case USER_LOGIN_REQUEST:
    case GET_USER_BY_COOKIE_REQUEST:
    case GET_USER_BY_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        userSignUpData: action.payload,
      };
    case USER_SIGN_UP_DATA_RESET:
      return {
        ...state,
        loading: false,
        userSignUpData: {},
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userLoginData: action.payload,
      };
    case USER_LOGIN_DATA_RESET:
      return {
        ...state,
        loading: false,
        userLoginData: {},
      };
    case GET_USER_BY_COOKIE_SUCCESS:
      return {
        ...state,
        loading: false,
        userByCookieData: action.payload,
      };
    case GET_USER_BY_TOKEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        userByTokenData: action.payload,
      };
    }
    case USER_SIGN_UP_FAILURE:
    case USER_LOGIN_FAILURE:
    case GET_USER_BY_COOKIE_FAILURE:
    case GET_USER_BY_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
