import {
  GET_ALL_MATH_LESSON_REQUEST,
  GET_ALL_MATH_LESSON_SUCCESS,
  GET_ALL_MATH_LESSON_FAILURE,
  GET_ALL_CZ_LESSON_REQUEST,
  GET_ALL_CZ_LESSON_SUCCESS,
  GET_ALL_CZ_LESSON_FAILURE,
  GET_MATH_LESSON_REQUEST,
  GET_MATH_LESSON_SUCCESS,
  GET_MATH_LESSON_FAILURE,
  GET_CZ_LESSON_REQUEST,
  GET_CZ_LESSON_SUCCESS,
  GET_CZ_LESSON_FAILURE,
  RESET_LESSON,
  GET_ALL_VIEW_LESSON_REQUEST,
  GET_ALL_VIEW_LESSON_SUCCESS,
  GET_ALL_VIEW_LESSON_FAILURE,
  GET_LESSON_TEST_ATTEMP_LIST_REQUEST,
  GET_LESSON_TEST_ATTEMP_LIST_SUCCESS,
  GET_LESSON_TEST_ATTEMP_LIST_FAILURE,
  GET_ALL_STUDENT_MAIN_LIST_REQUEST,
  GET_ALL_STUDENT_MAIN_LIST_SUCCESS,
  GET_ALL_STUDENT_MAIN_LIST_FAILURE,
  GET_LAST_USER_LESSON_REQUEST,
  GET_LAST_USER_LESSON_SUCCESS,
  GET_LAST_USER_LESSON_FAILURE,
} from "../constants/studentTopicLesson";

export function StudentTopicLessonReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_MATH_LESSON_REQUEST:
    case GET_ALL_CZ_LESSON_REQUEST:
    case GET_MATH_LESSON_REQUEST:
    case GET_CZ_LESSON_REQUEST:
    case GET_ALL_VIEW_LESSON_REQUEST:
    case GET_LESSON_TEST_ATTEMP_LIST_REQUEST:
    case GET_ALL_STUDENT_MAIN_LIST_REQUEST:
    case GET_LAST_USER_LESSON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_MATH_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllMathLessonData: action.payload,
      };
    case GET_ALL_CZ_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllCZLessonData: action.payload,
      };
    case GET_MATH_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        getMathLessonData: action.payload,
      };
    case GET_LESSON_TEST_ATTEMP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAttemptTestData: action.payload,
      };
    case GET_CZ_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        getCZLessonData: action.payload,
      };
    case GET_ALL_VIEW_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllViewLessonData: action.payload,
      };
    case GET_ALL_STUDENT_MAIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getStudentMainData: action.payload,
      };
    case GET_LAST_USER_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserLastLessonData: action.payload,
      };
    case RESET_LESSON:
      return {
        ...state,
        getCZLessonData: {},
        getMathLessonData: {},
      };
    case GET_ALL_MATH_LESSON_FAILURE:
    case GET_ALL_CZ_LESSON_FAILURE:
    case GET_MATH_LESSON_FAILURE:
    case GET_CZ_LESSON_FAILURE:
    case GET_ALL_VIEW_LESSON_FAILURE:
    case GET_LESSON_TEST_ATTEMP_LIST_FAILURE:
    case GET_ALL_STUDENT_MAIN_LIST_FAILURE:
    case GET_LAST_USER_LESSON_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
