// import axios from "axios";

import {
  GET_SOCIAL_ICON_FAILURE,
  GET_SOCIAL_ICON_REQUEST,
  GET_SOCIAL_ICON_SUCCESS,
} from "../constants/socialIcons";

export function SocialIcon(state = {}, action) {
  switch (action.type) {
    case GET_SOCIAL_ICON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SOCIAL_ICON_SUCCESS:
      return {
        ...state,
        loading: false,
        getSocialIconData: action.payload,
      };
    case GET_SOCIAL_ICON_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
