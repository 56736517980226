// import axios from "axios";
import {
  CREATE_CREDIT_REQUEST,
  CREATE_CREDIT_SUCCESS,
  CREATE_CREDIT_FAILURE,
  GET_USER_SCHOOL_LIST_REQUEST,
  GET_USER_SCHOOL_LIST_SUCCESS,
  GET_USER_SCHOOL_LIST_FAILURE,
  GET_REMAINING_CREDIT_COUNT_REQUEST,
  GET_REMAINING_CREDIT_COUNT_SUCCESS,
  GET_REMAINING_CREDIT_COUNT_FAILURE,
  GET_PARENT_CHILD_LIST_REQUEST,
  GET_PARENT_CHILD_LIST_SUCCESS,
  GET_PARENT_CHILD_LIST_FAILURE,
  TOTAL_EDU_PRGS,
  TOTAL_SCHOOLS,
} from "../constants/shoppingCart";

export function shoppingCartReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_CREDIT_REQUEST:
    case GET_USER_SCHOOL_LIST_REQUEST:
    case GET_REMAINING_CREDIT_COUNT_REQUEST:
    case GET_PARENT_CHILD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOTAL_EDU_PRGS:
      return {
        ...state,
        loading: false,
        totalEduPrgsData: action.payload || 0,
      };
    case TOTAL_SCHOOLS:
      return {
        ...state,
        loading: false,
        totalSchoolsData: action.payload || 0,
      };
    case CREATE_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        createCrediData: action.payload,
      };
    case GET_USER_SCHOOL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userSchoolData: action.payload,
      };
    case GET_REMAINING_CREDIT_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getCreditRemainCount: action.payload,
      };
    case GET_PARENT_CHILD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getParentChildData: action.payload,
      };
    case CREATE_CREDIT_FAILURE:
    case GET_USER_SCHOOL_LIST_FAILURE:
    case GET_REMAINING_CREDIT_COUNT_FAILURE:
    case GET_PARENT_CHILD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
