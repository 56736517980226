export const GET_ALL_MATH_LESSON_REQUEST = "GET_ALL_MATH_LESSON_REQUEST";
export const GET_ALL_MATH_LESSON_SUCCESS = "GET_ALL_MATH_LESSON_SUCCESS";
export const GET_ALL_MATH_LESSON_FAILURE = "GET_ALL_MATH_LESSON_FAILURE";

export const GET_ALL_CZ_LESSON_REQUEST = "GET_ALL_CZ_LESSON_REQUEST";
export const GET_ALL_CZ_LESSON_SUCCESS = "GET_ALL_CZ_LESSON_SUCCESS";
export const GET_ALL_CZ_LESSON_FAILURE = "GET_ALL_CZ_LESSON_FAILURE";

export const GET_MATH_LESSON_REQUEST = "GET_MATH_LESSON_REQUEST";
export const GET_MATH_LESSON_SUCCESS = "GET_MATH_LESSON_SUCCESS";
export const GET_MATH_LESSON_FAILURE = "GET_MATH_LESSON_FAILURE";

export const GET_CZ_LESSON_REQUEST = "GET_CZ_LESSON_REQUEST";
export const GET_CZ_LESSON_SUCCESS = "GET_CZ_LESSON_SUCCESS";
export const GET_CZ_LESSON_FAILURE = "GET_CZ_LESSON_FAILURE";

export const GET_ALL_VIEW_LESSON_REQUEST = "GET_ALL_VIEW_LESSON_REQUEST";
export const GET_ALL_VIEW_LESSON_SUCCESS = "GET_ALL_VIEW_LESSON_SUCCESS";
export const GET_ALL_VIEW_LESSON_FAILURE = "GET_ALL_VIEW_LESSON_FAILURE";

export const GET_LESSON_TEST_ATTEMP_LIST_REQUEST =
  "GET_LESSON_TEST_ATTEMP_LIST_REQUEST";
export const GET_LESSON_TEST_ATTEMP_LIST_SUCCESS =
  "GET_LESSON_TEST_ATTEMP_LIST_SUCCESS";
export const GET_LESSON_TEST_ATTEMP_LIST_FAILURE =
  "GET_LESSON_TEST_ATTEMP_LIST_FAILURE";

export const GET_ALL_STUDENT_MAIN_LIST_REQUEST =
  "GET_ALL_STUDENT_MAIN_LIST_REQUEST";
export const GET_ALL_STUDENT_MAIN_LIST_SUCCESS =
  "GET_ALL_STUDENT_MAIN_LIST_SUCCESS";
export const GET_ALL_STUDENT_MAIN_LIST_FAILURE =
  "GET_ALL_STUDENT_MAIN_LIST_FAILURE";

export const GET_LAST_USER_LESSON_REQUEST = "GET_LAST_USER_LESSON_REQUEST"
export const GET_LAST_USER_LESSON_SUCCESS = "GET_LAST_USER_LESSON_SUCCESS"
export const GET_LAST_USER_LESSON_FAILURE = "GET_LAST_USER_LESSON_FAILURE"

export const STORE_LESSON_PAGE_COORDS = "STORE_LESSON_PAGE_COORDS";

export const RESET_LESSON = "RESET_LESSON";
