export const GET_ALL_REGION_LIST_REQUEST = "GET_ALL_REGION_LIST_REQUEST";
export const GET_ALL_REGION_LIST_SUCCESS = "GET_ALL_REGION_LIST_SUCCESS";
export const GET_ALL_REGION_LIST_FAILURE = "GET_ALL_REGION_LIST_FAILURE";

export const GET_ALL_DISTRICT_LIST_REQUEST = "GET_ALL_DISTRICT_LIST_REQUEST";
export const GET_ALL_DISTRICT_LIST_SUCCESS = "GET_ALL_DISTRICT_LIST_SUCCESS";
export const GET_ALL_DISTRICT_LIST_FAILURE = "GET_ALL_DISTRICT_LIST_FAILURE";

export const GET_ALL_DISTRICT_BY_ID_LIST_REQUEST =
  "GET_ALL_DISTRICT_BY_ID_LIST_REQUEST";
export const GET_ALL_DISTRICT_BY_ID_LIST_SUCCESS =
  "GET_ALL_DISTRICT_BY_ID_LIST_SUCCESS";
export const GET_ALL_DISTRICT_BY_ID_LIST_FAILURE =
  "GET_ALL_DISTRICT_BY_ID_LIST_FAILURE";

export const GET_ALL_CITY_BY_ID_REQUEST = "GET_ALL_CITY_BY_ID_REQUEST";
export const GET_ALL_CITY_BY_ID_SUCCESS = "GET_ALL_CITY_BY_ID_SUCCESS";
export const GET_ALL_CITY_BY_ID_FAILURE = "GET_ALL_CITY_BY_ID_FAILURE";

export const GET_SCHOOLS_BY_CITY_ID_REQUEST = "GET_SCHOOLS_BY_CITY_ID_REQUEST";
export const GET_SCHOOLS_BY_CITY_ID_SUCCESS = "GET_SCHOOLS_BY_CITY_ID_SUCCESS";
export const GET_SCHOOLS_BY_CITY_ID_FAILURE = "GET_SCHOOLS_BY_CITY_ID_FAILURE";

export const GET_HIGH_SCHOOL_LIST_REQUEST = "GET_HIGH_SCHOOL_LIST_REQUEST";
export const GET_HIGH_SCHOOL_LIST_SUCCESS = "GET_HIGH_SCHOOL_LIST_SUCCESS";
export const GET_HIGH_SCHOOL_LIST_FAILURE = "GET_HIGH_SCHOOL_LIST_FAILURE";

export const STORE_SCHOOL_DATA = "STORE_SCHOOL_DATA";
export const STORE_DISTRICT_NAME_DATA = "STORE_DISTRICT_NAME_DATA";
