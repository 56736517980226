import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import { useDispatch } from "react-redux";
// import { RESET_TEST_QUESTION_DATA } from "../../store/constants/test";

function TestResult() {
  const { t } = useTranslation();
  // const { testQuestionData } = useSelector((state) => state.test);
  const { state } = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [score, setScore] = React.useState(null);
  const [lang, setLang] = React.useState("CZ");

  React.useEffect(() => {
    let lng = "cz";
    if (sessionStorage.getItem("langType")) {
      if (sessionStorage.getItem("langType") === "CZ") {
        lng = "cz";
      } else if (sessionStorage.getItem("langType") === "EN") {
        lng = "en";
      }
    }
    setLang(lng);
  }, []);

  React.useEffect(() => {
    if (state && Object.keys(state).length > 0 && state.data) {
      const data = state.data.map((i) => ({
        vLessonTitle: i.vLessonTitle,
        uspesnost: parseInt(i.uspesnost).toFixed(2),
      }));
      setScore(data);
    } else {
      navigate(`/${lang}/knowledge-verification-l`);
    }
  }, [navigate, lang, state]);

  return (
    <div className="details-left">
      <h1 className="sec-heading">
        {t("LBL_TEST_RESULT_TITLE")}
        {/* Test finished */}
      </h1>
      <p className="sub-capion">
        {t("LBL_TEST_RESULT_CAPTION_TEXT")}
        {/* The test is evaluated and ready to be sent to your email. In the
        following window, fill in the necessary data and you will receive an
        email with a detailed evaluation. */}
      </p>
      <div className="result-sec-text">
        <DataTable
          value={score}
          scrollable
          scrollHeight="500px"
          virtualScrollerOptions={{ itemSize: 46 }}
        >
          <Column
            field="vLessonTitle"
            header={t("LBL_TEST_RESULT_TBL_TITLE_1")}
          ></Column>
          <Column
            field="uspesnost"
            header={t("LBL_TEST_RESULT_TBL_TITLE_2")}
          ></Column>
        </DataTable>
        <br />
        {/* <p>
          {t("LBL_TEST_RESULT_P_TEXT")}:
          <span className="pink-color">
            {score && score !== null && score.school_avg
              ? parseInt(score.school_avg).toFixed(2) + "%"
              : "00%"}
          </span>
          {t("LBL_TEST_RESULT_P_SPAN_TEXT_1")}
          <span className="green-color">
            {score && score !== null && score.user_result
              ? parseInt(score.user_result).toFixed(2) + "%"
              : ""}
          </span>
          {t("LBL_TEST_RESULT_P_SPAN_TEXT_2")}
        </p> */}
        {/* <p>
          Statistics: <span className="pink-color">60%</span> of students get
          better results <span className="green-color">58%</span> of answers was
          correct
        </p> */}
        <p>{t("LBL_TEST_RESULT_P_TEXT_2")}</p>
        {/* <p>What do you want now?</p> */}
        <div className="action-btn">
          {sessionStorage.getItem("token") &&
          sessionStorage.getItem("token").length > 0 ? (
            <>
              <Link
                className="blue-btn btn"
                to={`/${lang}/knowledge-verification/${sessionStorage.getItem(
                  "token"
                )}`}
              >
                {t("LBL_TEST_RESULT_LINK")}
                {/* Another test */}
              </Link>
              <Link
                className="green-btn btn"
                type="button"
                to={
                  "/" +
                  lang +
                  "/student-auth-main/" +
                  sessionStorage.getItem("token")
                }
              >
                {t("LBL_TEST_RESULT_LINK_START_LEARNING_TEXT")}
                {/* Start Learning */}
              </Link>
            </>
          ) : (
            <>
              <Link
                className="blue-btn btn"
                to={`/${lang}/knowledge-verification-l`}
              >
                {t("LBL_TEST_RESULT_LINK")}
                {/* Another test */}
              </Link>
              <Link
                className="green-btn btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightsingup"
                aria-controls="offcanvasRightsingup"
                to={"/result-screen"}
              >
                {t("LBL_TEST_RESULT_LINK_BUTTON")}
                {/* Sign up */}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestResult;
