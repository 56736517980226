// const initialState = {};

import {
  GET_DIFFERENCE_ELEMENTARY_SCHOOL_FAILURE,
  GET_DIFFERENCE_ELEMENTARY_SCHOOL_REQUEST,
  GET_DIFFERENCE_ELEMENTARY_SCHOOL_SUCCESS,
  GET_DIFFERENCE_HIGH_SCHOOL_FAILURE,
  GET_DIFFERENCE_HIGH_SCHOOL_REQUEST,
  GET_DIFFERENCE_HIGH_SCHOOL_SUCCESS,
  GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_FAILURE,
  GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_REQEUST,
  GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_SUCCESS,
  GET_PARENT_SCHOOL_DETAILS_FAILURE,
  GET_PARENT_SCHOOL_DETAILS_REQUEST,
  GET_PARENT_SCHOOL_DETAILS_SUCCESS,
  GET_PARENT_STUDENT_CHILD_LIST_FAILURE,
  GET_PARENT_STUDENT_CHILD_LIST_REQUEST,
  GET_PARENT_STUDENT_CHILD_LIST_SUCCESS,
  STORE_PARENT_CHILD_DATA,
  STORE_SCHOOL_GRADE_DATA,
  STORE_SCHOOL_TYPE_DATA,
  STORE_USER_SCHOOL_DATA,
} from "../constants/parentSchoolDetails";

export function ParentSchoolDetails(state = {}, action) {
  switch (action.type) {
    case GET_PARENT_SCHOOL_DETAILS_REQUEST:
    case GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_REQEUST:
    case GET_PARENT_STUDENT_CHILD_LIST_REQUEST:
    case GET_DIFFERENCE_ELEMENTARY_SCHOOL_REQUEST:
    case GET_DIFFERENCE_HIGH_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PARENT_SCHOOL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        parentSchoolData: action.payload,
      };
    case GET_DIFFERENCE_ELEMENTARY_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        getDiffElementarySchoolData: action.payload,
      };
    case GET_DIFFERENCE_HIGH_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        getDiffHighSchoolData: action.payload,
      };
    case GET_PARENT_STUDENT_CHILD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        parentChildListData: action.payload,
      };
    case STORE_USER_SCHOOL_DATA:
      return {
        ...state,
        loading: false,
        parentUserSchoolData: action.payload,
      };
    case STORE_PARENT_CHILD_DATA:
      return {
        ...state,
        loading: false,
        parentChildData: action.payload,
      };
    case STORE_SCHOOL_GRADE_DATA:
      return {
        ...state,
        loading: false,
        schoolGradeData: action.payload,
      };
    case STORE_SCHOOL_TYPE_DATA:
      return {
        ...state,
        loading: false,
        schoolTypeData: action.payload,
      };
    case GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getElemAndHighSchoolData: action.payload,
      };
    case GET_PARENT_SCHOOL_DETAILS_FAILURE:
    case GET_ELEMENTARY_AND_HIGH_SCHOOL_LIST_FAILURE:
    case GET_PARENT_STUDENT_CHILD_LIST_FAILURE:
    case GET_DIFFERENCE_ELEMENTARY_SCHOOL_FAILURE:
    case GET_DIFFERENCE_HIGH_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
