import { GET_FREE_TRIAL_SUCCESS } from "../constants/freeTrial";

export function FreeTrial(state = {}, action) {
  switch (action.type) {
    case GET_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        getFreeTrialData: action.payload,
      };
    default:
      return state;
  }
}
